<template>
    <div class="settings">
        <v-row class="mx-0 my-10">
            <p v-if="!isChange">
                Информация будет проверена менеджером. В рабочие часы это занимает несколько минут. Вам на
                почту придет оповещение, когда подключение будет завершено
            </p>
            <div v-else>
                <p>У данного источника нет настроек, все настраивается автоматически</p>
            </div>
        </v-row>
        <v-row justify="end" class="ma-0"
            ><v-btn @click="goToProject" outlined class="mr-3 mt-2 cancel-btn">Завершить</v-btn>
        </v-row>
    </div>
</template>

<script>
import { objectEquals } from '@/utils.js'

import { mapState } from 'vuex'
import cfg from '../../../../config'
export default {
    name: 'ProfSalonConnectorSettings',
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },
        connector: {
            type: Object,
            default: null,
        },
    },
    components: {},
    data: () => ({
        cfg,
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
    },

    methods: {
        goToProject() {
            this.$parent.$emit('goToProject')
        },
    },
    async created() {},
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
</style>
