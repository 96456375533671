export const yClientsService = {
    getConnectionStatusText(status: any) {
        if (status === 'active') {
            return 'Подключено'
        }
        if (status === 'freezed') {
            return 'Заморожено'
        }
        if (status === 'inactive') {
            return 'Не подключено'
        }
    },
}
