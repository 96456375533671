<template>
    <div class="my-10">
        <div class="form">
            <TextField v-model="creds.email" label="Email Арника" />
            <TextField v-model="creds.password" label="Пароль Арника" type="password" />
        </div>
        <v-row justify="end">
            <v-btn @click="save" color="main" class="mr-3 mt-6 white--text" :loading="loading"
                >Сохранить</v-btn
            >
            <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn">Отмена</v-btn>
            <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn"
                >Не добавлять источник</v-btn
            >
        </v-row>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import SpinnerLoader from '../../SpinnerLoader.vue'
import TextField from '../../inputs/TextField.vue'
import { saveEmailAndPassword } from '@root/src/api/connectors/arnica'

export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { SpinnerLoader, TextField },
    data: () => ({
        loading: false,
        creds: { email: '', password: '' },
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        testCallBtnDisabled() {
            return !this.testCall.phone || !this.testCall.userId
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        currentConnector() {
            return this.isChange ? this.connector : this.loggedAddingConnector
        },
    },
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        goToProject() {
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}#connectors`)
        },

        async save() {
            this.loading = true
            const { data, error } = await saveEmailAndPassword(
                this.currentConnector.connectorId,
                this.creds.email,
                this.creds.password
            )
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка сохранения')
                this.loading = false
                return
            }
            if (!this.isChange) {
                this.$parent.$emit('createConnector', {})
            } else {
                this.$parent.$emit('changeSettings', {})
            }
            this.loading = false
        },
    },
    async created() {},
}
</script>
<style lang="sass" scoped>
.form
    max-width: 250px
</style>
