import { CONNECTOR_TYPES } from '../../vars/general'
import DefaultSettings from './DefaultSettings'
import AlfaSettings from '@/components/connectors/alfa/Settings.vue'
import PlanfactSettings from '@/components/connectors/planfact/Settings.vue'
import YCSettings from '@/components/connectors/yClients/Settings.vue'
import USSettings from '@/components/connectors/universeSoft/Settings.vue'
import ZenmoneySettings from '@/components/connectors/zenmoney/Settings.vue'
import ProfSalonSettings from '@/components/connectors/profSalon/Settings.vue'
import SycretSettings from '@/components/connectors/sycret/Settings.vue'
import SonlineSettings from '@/components/connectors/sonline/Settings.vue'
import ArnicaSettings from '@root/src/components/connectors/arnica/Settings.vue'
import MoiSkladSettings from '@/components/connectors/moiSklad/Settings.vue'
import Helix1CSettings from '@/components/connectors/helix1C/Settings'
import LenaLeninaSettings from '@/components/connectors/lenaLenina/Settings.vue'
import WhatsappSettings from '@/components/connectors/whatsapp/Settings.vue'
import AltegioSettings from '@/components/connectors/altegio/Settings.vue'
import ProfSalonBatSettings from '@root/src/components/connectors/profSalonBat/Settings.vue'
import DikidiSettings from '@root/src/components/connectors/dikidi/Settings'
import Ruslan1CSettings from '@root/src/components/connectors/ruslan1C/Settings.vue'
import ArchimedSettings from '@root/src/components/connectors/archimed/Settings'
import TelephonyMegaphoneSettings from '@root/src/components/connectors/telephonyMegaphone/Settings.vue'
import TelephonyProstyiZvonkiSettings from '@/components/connectors/telephonyProstyiZvonki/Settings.vue'
import TelephonyBeelineSettings from '@/components/connectors/telephonyBeeline/Settings.vue'
import TelephonyMangoSettings from '@/components/connectors/telephonyMango/Settings.vue'
import AmoCrmSettings from '@/components/connectors/amoCrm/Settings'
import AmoCrmFullSettings from '@/components/connectors/amoCrmFull/Settings'
//TODO: переписать на динамические импорты
export default [
    { connectorType: CONNECTOR_TYPES.alfa, component: AlfaSettings },
    { connectorType: CONNECTOR_TYPES.yClients, component: YCSettings },
    { connectorType: CONNECTOR_TYPES.zenmoney, component: ZenmoneySettings },
    { connectorType: CONNECTOR_TYPES.universeSoft, component: USSettings },
    { connectorType: CONNECTOR_TYPES.sycret, component: SycretSettings },
    { connectorType: CONNECTOR_TYPES.profSalonBat, component: ProfSalonBatSettings },
    { connectorType: CONNECTOR_TYPES.profSalonBatV2, component: ProfSalonBatSettings },
    { connectorType: CONNECTOR_TYPES.sonline, component: SonlineSettings },
    { connectorType: CONNECTOR_TYPES.arnica, component: ArnicaSettings },
    { connectorType: CONNECTOR_TYPES.moiSklad, component: MoiSkladSettings },
    { connectorType: CONNECTOR_TYPES.helix1C, component: Helix1CSettings },
    { connectorType: CONNECTOR_TYPES.whatsapp, component: WhatsappSettings },
    { connectorType: CONNECTOR_TYPES.lenaLenina, component: LenaLeninaSettings },
    { connectorType: CONNECTOR_TYPES.altegio, component: AltegioSettings },
    { connectorType: CONNECTOR_TYPES.dikidi, component: DikidiSettings },
    { connectorType: CONNECTOR_TYPES.ruslan1C, component: Ruslan1CSettings },
    { connectorType: CONNECTOR_TYPES.archimed, component: ArchimedSettings },
    { connectorType: CONNECTOR_TYPES.amoCrm, component: AmoCrmSettings },
    { connectorType: CONNECTOR_TYPES.amoCrmFull, component: AmoCrmFullSettings },
    { connectorType: CONNECTOR_TYPES.telephonyMegaphone, component: TelephonyMegaphoneSettings },
    { connectorType: CONNECTOR_TYPES.telephonyProstyiZvonki, component: TelephonyProstyiZvonkiSettings },
    { connectorType: CONNECTOR_TYPES.dentalPro, component: DefaultSettings },
    { connectorType: CONNECTOR_TYPES.klientix, component: DefaultSettings },
    { connectorType: CONNECTOR_TYPES.cloud1C, component: DefaultSettings },
    { connectorType: CONNECTOR_TYPES.arnicaV2, component: DefaultSettings },
    { connectorType: CONNECTOR_TYPES.prostoSMS, component: DefaultSettings },
    {
        connectorType: CONNECTOR_TYPES.sipuni,
        component: () => import('@/components/connectors/sipuni/Settings.vue'),
    },
    {
        connectorType: CONNECTOR_TYPES.pbxOnline,
        component: () => import('@/components/connectors/telephonyPbx/Settings.vue'),
    },
    {
        connectorType: CONNECTOR_TYPES.whatsappBasis,
        component: () => import('@/components/connectors/whatsappBasis/Settings.vue'),
    },
    { connectorType: CONNECTOR_TYPES.telephonyBeeline, component: TelephonyBeelineSettings },
    { connectorType: CONNECTOR_TYPES.telephonyMango, component: TelephonyMangoSettings },
]
