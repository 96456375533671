<template>
    <div class="settings">
        <SpinnerLoader v-if="loading"></SpinnerLoader>
        <div v-else>
            <div class="user my-6" v-for="dhUser in users" :key="dhUser.id">
                <span class="user-label mr-12">{{ dhUser.name }}</span>
                <div class="user-input-wrapper">
                    <BaseInputOutlined
                        hide-details
                        v-model="settings.usersMapping[dhUser.id]"
                    ></BaseInputOutlined>
                    <v-btn icon @click="deleteUserMapping(dhUser.id)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="my-12 test-call" v-if="isChange">
                <h2 class="dashboard">Сделать тестовый звонок</h2>
                <div>
                    <v-row class="mx-0 my-4" align="center">
                        <div class="test-call__label-wrapper">
                            <span>Номер телефона</span>
                        </div>
                        <ValidationInput
                            class="phone-input"
                            v-maska="'+#*'"
                            :rule="phoneValidator"
                            v-model="testCall.phone"
                            label="+79991234567"
                            hide-details
                        />
                    </v-row>
                    <v-row class="mx-0 my-4" align="center">
                        <div class="test-call__label-wrapper">
                            <span>От имени пользователя</span>
                        </div>
                        <CustomActivatorSelect v-model="testCall.userId" :items="users" :multiple="false">
                            <template v-slot:activator="{ selected, _id }">
                                <BaseInputOutlined
                                    label="Пользователь"
                                    hide-details
                                    class="select-input"
                                    append-icon="mdi-menu-down"
                                    :id="_id"
                                    :value="selected ? selected.name : ''"
                                    readonly
                                >
                                </BaseInputOutlined>
                            </template>
                        </CustomActivatorSelect>
                    </v-row>
                </div>

                <v-btn
                    @click="makeTestCall"
                    :disabled="testCallBtnDisabled"
                    color="main"
                    class="mt-6 white--text"
                    :loading="testCall.loading"
                >
                    Позвонить
                </v-btn>
            </div>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-6 white--text">Сохранить</v-btn>
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </div>
    </div>
</template>
<script>
import { fetchConnector } from '@root/src/api/projects'
import { mapState } from 'vuex'
import SpinnerLoader from '../../SpinnerLoader.vue'
import { getUsersList } from '@root/src/api/connectors/telephonyMegaphone'
import Select from '../../inputs/Select.vue'
import TextField from '../../inputs/TextField.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../inputs/CustomActivatorSelect.vue'
import { makeCall } from '@root/src/api/connectors/telephonyProstyiZvonki'
import ValidationInput from '../../inputs/ValidationInput.vue'
import phoneValidator, {isValidPhone} from '../../../helpers/validators/phoneValidator'

export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { SpinnerLoader, Select, TextField, BaseInputOutlined, CustomActivatorSelect, ValidationInput },
    data: () => ({
        phoneValidator,
        isValidPhone,
        loading: true,
        settings: null,
        testCall: {
            phone: '',
            userId: null,
            loading: false,
        },
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        testCallBtnDisabled() {
            return !isValidPhone(this.testCall.phone) || !this.testCall.userId
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        currentConnector() {
            return this.isChange ? this.connector : this.loggedAddingConnector
        },
        users() {
            return this.project.users
        },
    },
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        goToProject() {
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}#connectors`)
        },
        deleteUserMapping(userId) {
            this.settings = _.omit({ ...this.settings }, `usersMapping.${userId}`)
        },

        async save() {
            if (!this.isChange) {
                this.$parent.$emit('createConnector', { settings: this.settings })
            } else {
                this.$parent.$emit('changeSettings', {
                    settings: this.settings,
                })
            }
        },
        async makeTestCall() {
            this.testCall.loading = true
            setTimeout(() => {
                this.testCall.loading = false
            }, 60 * 1000)
            const { data, error } = await makeCall({
                connectorId: this.currentConnector.connectorId,
                phone: this.testCall.phone,
                userId: this.testCall.userId,
            })

            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
        },
    },
    async created() {
        this.loading = true
        this.settings = { usersMapping: { ...this.currentConnector.usersMapping } || {} }
        this.loading = false
    },
}
</script>
<style lang="sass" scoped>
.user
    display: flex
    justify-content: space-between
    align-content: center
.user-label
    width: 500px
    display: inline-flex
    align-items: flex-end
.user-input-wrapper
    display: flex
.test-call
    &__label-wrapper
        width: 400px
.phone-input
    margin-bottom: 0px
</style>
