<template>
    <div class="settings">
        <h2 class="mt-10">У данного коннектора нет настроек, все настраивается автоматически</h2>
        <v-form v-model="valid">
            <v-row>
                <v-col>
                    <v-spacer></v-spacer>
                </v-col>
                <v-col>
                    <v-spacer></v-spacer>
                </v-col>
            </v-row>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-2 white--text" :disabled="disableSaveBtn"
                    >Сохранить</v-btn
                >
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { objectEquals } from '@/utils.js'
export default {
    name: 'ZenmoneySettings',
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },
        settingsChanged() {
            return !objectEquals(this.settings, this.prevSettings)
        },
        connector: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        valid: false,
        group: null,
        division: null,
        companyIds: [],
        companies: [],
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        disableSaveBtn() {
            if (this.isChange && !this.settingsChanged) {
                return true
            } else {
                return !this.valid
            }
        },
    },
    methods: {
        getSettings() {
            return {}
        },

        cancel() {
            this.$parent.$emit('cancel')
        },
        async save() {
            if (!this.isChange) {
                const settings = this.getSettings()
                this.$parent.$emit('createConnector', { settings })
            } else {
                this.$parent.$emit('changeSettings', {})
            }
        },
    },
    async created() {
        if (!this.isChange) {
        } else {
        }
    },
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
</style>
