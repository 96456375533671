<template>
    <div class="settings">
        <v-form>
            <v-row class="mt-6">
                <div class="mx-2 width-330">
                    <v-file-input
                        :loading="loading"
                        v-model="file"
                        filled
                        label="Выберете файл"
                        :error="fileUploadError"
                    ></v-file-input>
                    <v-btn
                        @click="loadFile"
                        color="main"
                        class="ml-8 white--text"
                        :loading="loading"
                        :disabled="!file"
                        >Загрузить</v-btn
                    >
                </div>
            </v-row>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-2 white--text">Сохранить</v-btn>
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Select from '@/components/inputs/Select'
import { uploadFile } from '@root/src/api/connectors/moiSklad'
import { setSnackBarNotification } from '@root/src/api/notification'
export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { Select },
    data: () => ({
        file: null,
        loading: false,
        fileUploadError: false,
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
    },
    watch: {},
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        save() {
            if (!this.isChange) {
                this.$parent.$emit('createConnector', {})
            } else {
                this.$parent.$emit('cancel')
            }
        },
        async loadFile() {
            this.loading = true
            const res = await uploadFile(this.connector ?? this.loggedAddingConnector, this.file)
            this.loading = false
            if (res.status < 300) {
                this.$store.dispatch('callNotify', `Файл ${this.file.name} успешно загружен`)
                this.fileUploadError = false
                await setSnackBarNotification({
                    projectId: this.$router.currentRoute.params.id,
                    active: true,
                    type: 'GCB2',
                    name: 'newData',
                })
            } else {
                this.$store.dispatch('callNotify', `ОШИБКА при загрузке файла ${this.file.name}`)
                this.fileUploadError = true
            }
        },
    },
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
.width-330
    width: 330px !important
</style>
