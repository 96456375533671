<template>
    <div class="settings">
        <v-form v-model="valid">
            <v-row class="mt-6">
                <div class="mx-2 width-330">
                    <Select
                        itemText="name"
                        itemValue="id"
                        :selectionMaxLength="25"
                        v-model="companyIds"
                        :items="companies"
                        label="Филиалы"
                        color="black"
                        placeholder="Выбрать параметры "
                        :rules="[v => !!v.length || 'Параметр обязателен']"
                        multiple
                        filled
                        persistent-hint
                        hint="Обязательно"
                        tooltipText="Отметьте те филиалы, которые вы хотите загрузить в этот проект"
                    ></Select>
                </div>
            </v-row>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-2 white--text" :disabled="disableSaveBtn"
                    >Сохранить</v-btn
                >
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { objectEquals } from '@/utils.js'
import { mapState } from 'vuex'
import Select from '@/components/inputs/Select'
import cfg from '../../../../config'
import { getCompanies } from '@/api/connectors/universeSoft'
export default {
    name: 'YClientsSettings',
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { Select },
    data: () => ({
        valid: false,
        companyIds: [],
        companies: [],
        settings: null,
        cfg,
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        settingsChanged() {
            return !objectEquals(this.settings, this.prevSettings)
        },
        disableSaveBtn() {
            if (this.isChange && !this.settingsChanged) {
                return true
            } else {
                return !this.valid
            }
        },
    },
    watch: {
        group() {
            this.settings = this.getSettings()
        },
        division() {
            this.settings = this.getSettings()
        },
        companyIds() {
            this.settings = this.getSettings()
        },
    },
    methods: {
        getSettings() {
            return {
                companyIds: this.companyIds,
            }
        },
        cancel() {
            this.$parent.$emit('cancel')
        },
        async save() {
            if (!this.isChange) {
                const settings = this.getSettings()
                this.$parent.$emit('createConnector', { settings })
            } else {
                this.$parent.$emit('changeSettings', {
                    prevSettings: this.prevSettings,
                    settings: this.getSettings(),
                    additionalData: ['fullRawUpdate', 'updateDataLayers'],
                })
            }
        },
    },
    async created() {
        if (!this.isChange) {
            const connectorId = this.loggedAddingConnector.connectorId
            this.$store.commit('startLoading')
            //this.companies = await this.$store.dispatch('us/getCompanies', connectorId)
            this.companies = await getCompanies(connectorId)
            this.$store.commit('endLoading')
        } else {
            this.$store.commit('startLoading')
            //this.companies = await this.$store.dispatch('us/getCompanies', this.connector.connectorId)
            this.companies = await getCompanies(this.connector.connectorId)
            this.$store.commit('endLoading')
            this.companyIds = this.connector.companyIds
            this.prevSettings = this.getSettings()
            this.settings = this.getSettings()
        }
    },
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
.width-330
    width: 330px !important
</style>
