
<template>
    <v-autocomplete
        v-on="$listeners"
        :items="items"
        :item-text="itemText"
        :return-object="returnObject"
        :placeholder="placeholder"
        :filled="filled"
        :color="color"
        :hint="hint"
        :persistent-hint="persistentHint"
        :label="label"
        :rules="rules"
        :value="value"
        @input="update"
    >
        <template v-slot:message="{ message }">
            <v-row class="ml-1" justify="space-between">
                <span>{{ message }}</span>

                <v-tooltip bottom color="#000000DE" max-width="190px" v-if="tooltipText">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="hint-icon" v-bind="attrs" v-on="on">mdi-help-circle </v-icon>
                    </template>
                    <span class="white--text"> {{ tooltipText }}</span>
                </v-tooltip>
            </v-row>
        </template>
    </v-autocomplete>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Autocomplete',
    data: () => ({}),
    //hint - короткая подсказка
    //tooltipText - расширенный техт подсказки
    props: {
        value: {
            type: null,
            default: undefined,
        },
        itemText: {
            type: String,
            default: undefined,
        },
        returnObject: {
            type: Boolean,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: 'black',
        },
        hint: {
            type: String,
            default: undefined,
        },
        persistentHint: {
            type: Boolean,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        rules: {
            type: null, //any
            default: undefined,
        },
        items: {
            type: Array,
            default: [],
        },
        tooltipText: {
            type: String,
            default: undefined,
        },
    },
    components: {},
    methods: {
        update(newValue) {
            this.$emit('input', newValue)
        },
    },
    created() {},
}
</script>
<style lang='sass' scoped>
@import '@/vars.sass'
.hint-icon
    color: $hint-icon-color
    opacity: 0.38
.hint-icon:hover
    color: black
    cursor: pointer
    opacity: 1
</style>