<template>
    <div class="settings">
        <v-form v-model="valid">
            <v-row class="mt-6">
                <Select
                    v-model="division"
                    class="mx-2"
                    :items="groupAndDivisonList"
                    itemText="name"
                    :returnObject="true"
                    placeholder="Выбрать параметр "
                    filled
                    color="black"
                    label="Подразделение"
                    :rules="[(v) => !!v || 'Параметр обязателен']"
                ></Select>
                <Select
                    v-model="group"
                    class="mx-2"
                    :items="groupAndDivisonList"
                    itemText="name"
                    :returnObject="true"
                    placeholder="Выбрать параметр "
                    filled
                    color="black"
                    label="Отделение"
                    :rules="[(v) => !!v || 'Параметр обязателен']"
                ></Select>

                <Autocomplete
                    v-model="currency"
                    class="mx-2"
                    :items="currenciesCodes"
                    item-text="name"
                    return-object
                    placeholder="Выбрать параметр "
                    filled
                    :rules="[(v) => !!v || 'Параметр обязателен']"
                    color="black"
                    label="Валюта"
                ></Autocomplete>
            </v-row>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-2 white--text" :disabled="disableSaveBtn"
                    >Сохранить</v-btn
                >
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { objectEquals } from '@/utils.js'
import { mapState, mapGetters } from 'vuex'
import Select from '@/components/inputs/Select'
import Autocomplete from '@/components/inputs/Autocomplete'

export default {
    name: 'AlfaSettings',
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { Select, Autocomplete },
    data: () => ({
        valid: false,
        group: null,
        division: null,
        currency: null,
        settings: null,
    }),
    computed: {
        ...mapState(['serviceTableName']),
        ...mapState('alfa', ['groupAndDivisonList']),
        ...mapState('project', ['loggedAddingConnector']),
        ...mapGetters(['currenciesCodes']),
        disableSaveBtn() {
            if (this.isChange && !this.settingsChanged) {
                return true
            } else {
                return !this.valid
            }
        },
        settingsChanged() {
            return !objectEquals(this.settings, this.prevSettings)
        },
    },
    watch: {
        group() {
            this.settings = this.getSettings()
        },
        division() {
            this.settings = this.getSettings()
        },
        currency() {
            this.settings = this.getSettings()
        },
    },
    methods: {
        getSettings() {
            return {
                group: this.group,
                division: this.division,
                currency: `${this.serviceTableName}/${this.currency}`,
            }
        },

        cancel() {
            this.$parent.$emit('cancel')
        },
        async save() {
            if (!this.isChange) {
                const settings = this.getSettings()
                this.$parent.$emit('createConnector', {settings})
            } else {
                let additionalData = []
                if (this.group !== this.prevSettings.group || this.division !== this.prevSettings.division) {
                    additionalData = ['updateServiceProvided']
                }
                this.$parent.$emit('changeSettings', {
                    prevSettings: this.prevSettings,
                    settings: this.getSettings(),
                    additionalData,
                })
            }
        },
    },
    created() {
        this.$store.dispatch('setCurrencies')
        if (this.isChange) {
            this.group = this.connector.group
            this.division = this.connector.division
            this.currency = this.connector.currency.split('/')[1]
            this.prevSettings = this.getSettings()
            this.settings = this.getSettings()
        }
    },
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
</style>
