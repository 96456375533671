<template>
    <div class="settings">
        <SpinnerLoader v-if="loading"></SpinnerLoader>
        <div v-else>
            <div class="user my-6" v-for="dhUser in users.dhUsers" :key="dhUser.id">
                <span class="user-label mr-12">{{ dhUser.name }}</span>
                <Select
                    dense
                    hide-details
                    class="user-input"
                    :selectionMaxLength="100"
                    :filled="false"
                    item-text="name"
                    item-value="id"
                    v-model="settings.usersMapping[dhUser.id]"
                    :items="users.beelineUsers"
                    label="Пользователь телефонии"
                ></Select>
                <v-btn icon @click="deleteUserMapping(dhUser.id)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-6 white--text">Сохранить</v-btn>
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import SpinnerLoader from '../../SpinnerLoader.vue'
import Select from '../../inputs/Select.vue'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import api from '@root/src/api/api'
export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { SpinnerLoader, Select, CustomActivatorSelect, BaseInputOutlined },
    data: () => ({
        loading: true,
        users: {},
        settings: null,
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        currentConnector() {
            return this.isChange ? this.connector : this.loggedAddingConnector
        },
        testCallUsers() {
            return this.project.users.map(el =>
                Object.assign({}, el, { disabled: !this.settings?.usersMapping[el.id] })
            )
        },
        testCallBtnDisabled() {
            return !this.testCall.phone || !this.testCall.userId
        },
    },
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        goToProject() {
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}#connectors`)
        },
        deleteUserMapping(userId) {
            this.settings = _.omit({ ...this.settings }, `usersMapping.${userId}`)
        },
        async save() {
            if (!this.isChange) {
                this.$parent.$emit('createConnector', { settings: this.settings })
            } else {
                this.$parent.$emit('changeSettings', {
                    settings: this.settings,
                })
            }
        },
    },
    async created() {
        this.loading = true
        this.settings = { usersMapping: { ...this.currentConnector.usersMapping } || {} }
        this.users = await api.connectors.telephonyBeeline
            .getUsers(this.currentConnector.connectorId)
            .then(res => ({
                dhUsers: res.data.dhUsers,
                beelineUsers: res.data.beelineUsers.map(el => ({
                    id: el.userId.split('@')[0],
                    name: el.userId.split('@')[0],
                })),
            }))
        this.loading = false
    },
}
</script>
<style lang="sass" scoped>
.user
    display: flex
    justify-content: space-between
    align-content: center
.user-label
    width: 500px
    display: inline-flex
    align-items: flex-end
.user-input
    width: 300px

.test-call
    &__label-wrapper
        width: 400px
</style>
