<template>
    <div class="settings">
        <v-row class="mx-0 my-10">
            <p>У данного источника нет настроек, все настраивается автоматически</p>
        </v-row>
        <v-row justify="end" class="ma-0">
            <!--  <v-btn @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отменить</v-btn> -->
            <v-btn @click="isChange ? goToProject() : createConnector()" outlined class="mr-3 mt-2 cancel-btn"
                >Завершить</v-btn
            >
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import cfg from '../../../../config'
export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: {},
    data: () => ({
        valid: false,
        cfg,
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
    },
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        goToProject() {
            console.log('goTo')
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}#connectors`)
        },
        createConnector() {
            this.$parent.$emit('createConnector', {})
        },
    },
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
.width-330
    width: 330px !important
</style>
