<template>
    <div class="settings">
        <v-form v-model="valid">
            <v-row class="mt-6">
                <div class="mx-2 width-330">
                    <Select
                        itemText="title"
                        itemValue="id"
                        :selectionMaxLength="25"
                        v-model="salonId"
                        :items="salons"
                        label="Филиалы"
                        placeholder="Выбрать параметры "
                        :rules="[v => !!v || 'Параметр обязателен']"
                        filled
                        persistent-hint
                        hint="Обязательно"
                        tooltipText="Отметьте те филиалы, которые вы хотите загрузить в этот проект"
                    ></Select>
                </div>
            </v-row>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-2 white--text" :disabled="disableSaveBtn"
                    >Сохранить</v-btn
                >
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-2 cancel-btn"
                    >Не добавлять источник</v-btn
                >
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { objectEquals } from '@/utils.js'
import { mapState } from 'vuex'
import Select from '@/components/inputs/Select'
import cfg from '../../../../config'
import { getSalons } from '@root/src/api/connectors/sonline'
export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { Select },
    data: () => ({
        valid: false,
        salonId: [],
        salons: [],
        settings: null,
        cfg,
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector']),
        settingsChanged() {
            return !objectEquals(this.settings, this.prevSettings)
        },
        disableSaveBtn() {
            if (this.isChange && !this.settingsChanged) {
                return true
            } else {
                return !this.valid
            }
        },
    },
    watch: {
        salonId() {
            this.settings = this.getSettings()
        },
    },
    methods: {
        getSettings() {
            return {
                salonId: this.salonId,
            }
        },
        cancel() {
            this.$parent.$emit('cancel')
        },
        async save() {
            if (!this.isChange) {
                const settings = this.getSettings()
                this.$parent.$emit('createConnector', { settings })
            } else {
                this.$parent.$emit('changeSettings', {
                    prevSettings: this.prevSettings,
                    settings: this.getSettings(),
                    additionalData: [],
                })
            }
        },
    },
    async created() {
        if (!this.isChange) {
            const connectorId = this.loggedAddingConnector.connectorId
            this.$store.commit('startLoading')
            this.salons = await getSalons(connectorId)
            this.$store.commit('endLoading')
        } else {
            this.$store.commit('startLoading')
            this.salons = await getSalons(this.connector.connectorId)
            this.$store.commit('endLoading')
            this.salonId = this.connector.salonId
            this.prevSettings = this.getSettings()
            this.settings = this.getSettings()
        }
    },
}
</script>
<style lang="sass" scoped>
.cancel-btn
    border-color: #0000001F !important
.width-330
    width: 330px !important
</style>
